import { FaEdit } from "react-icons/fa";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  productAtom,
  searchProductsList,
  showProductAddModal,
} from "store/products/products";
import ProductDisable from "../ProductDisable/ProductDisable";
import style from "./SearchProduct.module.css";

export default function SearchProduct({ setPageNo, pageCount, pageNo }: any) {
  const [searchProducts, setSearchProducts] =
    useRecoilState(searchProductsList);
  const [editProductData, setEditProductData] = useRecoilState(productAtom);
  const setShowProductModal = useSetRecoilState(showProductAddModal);
  return (
    <>
      <>
        <div className={style.box}>
          {searchProducts?.length > 0 ? (
            searchProducts?.map((product: any) => {
              return (
                <div key={product?._id} className={style.wrapper}>
                  <div className={style.product_container}>
                    <div className={style.image_container}>
                      {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
                      <img
                        width='60px'
                        src={
                          product?.thumbImages[0]?.image ||
                          "https://cdn3.iconfinder.com/data/icons/graphic-and-web-design/64/PACKAGING_DESIGN-1024.png"
                        }
                        alt='Product'
                      />
                    </div>
                    <div className={style.product_name}>
                      <p>{product?.name}</p>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "60%",
                        }}
                      >
                        <p>MRP : ₹{product?.minPrice?.price}</p>
                        <p>
                          Selling Price : ₹{product?.minPrice?.sellingPrice}
                        </p>
                      </div>
                    </div>
                  </div>

                  <FaEdit
                    className={style.edit_icon}
                    onClick={(e) => {
                      e.stopPropagation();
                      setEditProductData(product);
                      setShowProductModal(true);
                    }}
                  />

                  <div style={{ float: "right" }}>
                    <ProductDisable key={product._id} product={product} />
                  </div>
                </div>
              );
            })
          ) : (
            <h3
              style={{
                textAlign: "center",
                padding: "1rem",
                backgroundColor: "#f3f4f9 ",
              }}
            >
              There are no products.
            </h3>
          )}

          {/* <Stack
            spacing={2}
            sx={{
              float: "right",
              paddingBottom: "1rem",
              marginTop: "1rem",
            }}
          >
            <Pagination
              size='small'
              count={pageCount}
              color='primary'
              page={pageNo}
              onChange={(e: any, value: any) => {
                e.stopPropagation();
                setPageNo(value);
              }}
              renderItem={(item: any) => (
                <PaginationItem
                  {...item}
                  sx={{
                    ...item.sx,
                    fontWeight: "bolder",
                  }}
                />
              )}
            />
          </Stack> */}
        </div>
      </>
    </>
  );
}
